<script setup lang="ts">
import { ToastRoot, useEmitAsProps, type ToastRootEmits } from "radix-vue";
import type { ToastProps } from "./toast-props";
import { toastVariants } from "./toast-variants";

const props = defineProps<ToastProps>();
const emits = defineEmits<ToastRootEmits>();
</script>

<template>
  <ToastRoot
    v-bind="{ ...props, ...useEmitAsProps(emits) }"
    :class="cn(toastVariants({ variant: props.variant }), props.class)"
    @update:open="onOpenChange"
  >
    <slot />
  </ToastRoot>
</template>
