<script setup lang="ts">
import { ToastDescription, type ToastDescriptionProps } from "radix-vue";
import type { HTMLAttributes } from "vue";

const props = defineProps<
  ToastDescriptionProps & { class?: HTMLAttributes["class"] }
>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <ToastDescription
    v-bind="delegatedProps"
    :class="cn('flex items-center text-sm opacity-90', props.class)"
  >
    <slot />
  </ToastDescription>
</template>
