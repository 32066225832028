<script setup lang="ts">
import { setZodErrorsLocale } from "~/server/validations";

const { t, locale } = useI18n();
setZodErrorsLocale(locale.value);

useHead({
  link: [{ rel: "icon", href: "/favicon.ico" }],
  meta: [
    {
      name: "facebook-domain-verification",
      content: "7cd7y404ydjtm6j841kj2algc6t4pe",
    },
  ],
});

/* ***************** SEO ***************** */
const { SITE_URL } = useRuntimeConfig().public;

const TITLE = t("app.title");
const DESCRIPTION = t("app.description");

useSeoMeta({
  title: TITLE,
  description: DESCRIPTION,

  ogTitle: TITLE,
  ogDescription: DESCRIPTION,
  ogImage: "/images/makhbazti-logo-with-text.png",
  ogUrl: typeof SITE_URL === "string" ? SITE_URL : "",

  twitterTitle: TITLE,
  twitterDescription: DESCRIPTION,
  twitterImage: "/images/makhbazti-logo-with-text.png",
  twitterCard: "summary",
});

const head = useLocaleHead({
  identifierAttribute: "id",
  addSeoAttributes: true,
});
</script>

<template>
  <Html :lang="head.htmlAttrs?.lang">
    <Head>
      <Title>{{ TITLE }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <NuxtRouteAnnouncer />
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
      <UIToastToaster />
    </Body>
  </Html>
</template>
