<script setup lang="ts">
import { isVNode } from "vue";
import { useToast } from "./use-toast";
import ToastProvider from "./ToastProvider.vue";
import Toast from "./Toast.vue";
import ToastTitle from "./ToastTitle.vue";
import ToastDescription from "./ToastDescription.vue";
import ToastClose from "./ToastClose.vue";
import ToastViewport from "./ToastViewport.vue";

const { toasts } = useToast();
</script>

<template>
  <ToastProvider>
    <Toast v-for="toast in toasts" :key="toast.id" v-bind="toast">
      <div class="flex items-center gap-3">
        <Icon
          v-if="toast.variant === 'success' || toast.variant === undefined"
          name="ic:round-check-circle"
          class="size-5 text-tertiary-container"
        />
        <Icon
          v-if="toast.variant === 'error'"
          name="ic:round-error"
          class="text-error"
        />
        <Icon
          v-if="toast.variant === 'info'"
          name="ic:round-info"
          class="text-blue-800"
        />
        <Icon
          v-if="toast.variant === 'warning'"
          name="ic:round-warning"
          class="text-orange-700"
        />

        <div class="grid gap-1">
          <ToastTitle v-if="toast.title">
            {{ toast.title }}
          </ToastTitle>
          <template v-if="toast.description">
            <ToastDescription v-if="isVNode(toast.description)">
              <component :is="toast.description" />
            </ToastDescription>
            <ToastDescription v-else>
              {{ toast.description }}
            </ToastDescription>
          </template>
          <ToastClose />
        </div>
      </div>
      <component :is="toast.action" />
    </Toast>
    <div class="bottom-0 right-0 sm:absolute">
      <ToastViewport />
    </div>
  </ToastProvider>
</template>
