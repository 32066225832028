import revive_payload_client_UWtl3r9aXO from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_idb-keyval@6.2.1_ioredis_fx7wwhozl2dxho46jigzuuoksm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LGnycw0MAL from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_idb-keyval@6.2.1_ioredis_fx7wwhozl2dxho46jigzuuoksm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fFQyWWmWtx from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_idb-keyval@6.2.1_ioredis_fx7wwhozl2dxho46jigzuuoksm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Kf0i57TZw1 from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_idb-keyval@6.2.1_ioredis_fx7wwhozl2dxho46jigzuuoksm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_f8ER7BBKkB from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_idb-keyval@6.2.1_ioredis_fx7wwhozl2dxho46jigzuuoksm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LvVNlt8Tt2 from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_idb-keyval@6.2.1_ioredis_fx7wwhozl2dxho46jigzuuoksm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_QsxRzfIuGw from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_idb-keyval@6.2.1_ioredis_fx7wwhozl2dxho46jigzuuoksm/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_2uv5BNlbSC from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_idb-keyval@6.2.1_ioredis_fx7wwhozl2dxho46jigzuuoksm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_FErxAJqaC5 from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_idb-keyval@6.2.1_ioredis_fx7wwhozl2dxho46jigzuuoksm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_VGHEO28S8I from "/app/node_modules/.pnpm/nuxt-bugsnag@8.0.0_@bugsnag+core@8.1.1_magicast@0.3.5_rollup@4.27.2_vue-router@4.4.5_vue@3.5.13_typescript@5.6.3__/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_client_JFduVUWDN6 from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.27.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_JDSKVNdQLf from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.2_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_YaepxjYdPv from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.2_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_WxSQXc8Jf7 from "/app/node_modules/.pnpm/@nuxt+icon@1.7.5_magicast@0.3.5_rollup@4.27.2_vite@5.4.11_@types+node@22.9.0_terser@5.36.0__vue@3.5.13_typescript@5.6.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import posthog_client_fUucxKWhZE from "/app/plugins/posthog.client.ts";
export default [
  revive_payload_client_UWtl3r9aXO,
  unhead_LGnycw0MAL,
  router_fFQyWWmWtx,
  payload_client_Kf0i57TZw1,
  navigation_repaint_client_f8ER7BBKkB,
  check_outdated_build_client_LvVNlt8Tt2,
  view_transitions_client_QsxRzfIuGw,
  chunk_reload_client_2uv5BNlbSC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FErxAJqaC5,
  plugin_VGHEO28S8I,
  plugin_client_JFduVUWDN6,
  plugin_wy0B721ODc,
  switch_locale_path_ssr_JDSKVNdQLf,
  i18n_YaepxjYdPv,
  plugin_WxSQXc8Jf7,
  posthog_client_fUucxKWhZE
]