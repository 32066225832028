import { init } from "i18next";
import { zodI18nMap } from "zod-i18n-map";
import translation from "zod-i18n-map/locales/fr/zod.json";

/**
 * locale param should be 'en' or 'fr'
 */
export const setZodErrorsLocale = (locale: string) => {
  // lng and resources key depend on your locale.
  init({
    lng: locale,
    resources: {
      en: {
        zod: {
          errors: {
            invalid_type: "Required",
          },
        },
      },
      fr: {
        zod: {
          ...translation,
          errors: {
            ...translation.errors,
            invalid_type: "Obligatoire",
          },
        },
      },
    },
  });

  z.setErrorMap(zodI18nMap);
};
