import posthog from "posthog-js";

export default defineNuxtPlugin(() => {
  if (import.meta.dev) return;

  interface PosthogConfig {
    PUBLIC_KEY: string;
    HOST?: string;
  }

  const { PUBLIC_KEY, HOST } = useRuntimeConfig().public
    .POSTHOG as PosthogConfig;

  const posthogClient = posthog.init(PUBLIC_KEY, {
    api_host: HOST || "https://app.posthog.com",
    ui_host: "https://us.posthog.com",
    capture_pageview: false, // we add manual pageview capturing below
    loaded: (posthog) => {
      if (import.meta.dev) posthog.debug();
    },
    session_recording: {
      maskAllInputs: false,
      maskTextClass: "posthog-block",
    },
  });

  // Make sure that pageviews are captured with each route change
  const router = useRouter();
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture("$pageview", {
        current_url: to.fullPath,
      });
    });
  });

  return {
    provide: {
      posthog: () => posthogClient,
    },
  };
});
